var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("page-header", {
        attrs: {
          "show-back-icon": "",
          title: _vm.project.name + " / " + _vm.$t("Edit project")
        },
        on: { back: _vm.handleBackToProjectList }
      }),
      _c(
        "a-card",
        { staticClass: "mt-sm", attrs: { loading: _vm.loading } },
        [
          _c(
            "a-tabs",
            {
              attrs: { type: "card" },
              on: { change: _vm.onChangeTab },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "a-tab-pane",
                { key: "general", attrs: { tab: _vm.$t("General") } },
                [_c("General")],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "message", attrs: { tab: _vm.$t("Message") } },
                [_c("Message")],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "schedule", attrs: { tab: _vm.$t("Schedule") } },
                [_c("Scheduling")],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "reading-hp",
                  attrs: { tab: _vm.$t("Password Setting") }
                },
                [
                  _c("ReadingHP", {
                    attrs: { "company-id": _vm.companyId, project: _vm.project }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }