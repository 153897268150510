//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
export default {
  components: {
    PageHeader,
    General: () => import('./components/General.vue'),
    Scheduling: () => import('./components/Scheduling.vue'),
    ReadingHP: () => import('./components/ReadingHP.vue'),
    Message: () => import('./components/Message.vue')
  },
  data() {
    return {
      project: {},
      loading: false,
      activeTab: 'general'
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    companyId() {
      return this.userInfo.company_id;
    },
    projectId() {
      return this.$route.params.projectId;
    }
  },

  async created() {
    try {
      const { tab } = this.$route.query;
      this.activeTab = tab || 'general';
      this.loading = true;
      const data = await this.$s.api.project.getProjectById(this.companyId, this.projectId);
      this.addProject({ payload: data });
      this.project = data;
      this.thumbnail = {
        url: data.thumbnail?.url,
        alt: data.thumbnail?.alt
      };
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions('project', ['addProject']),
    handleBackToProjectList() {
      this.$router.go(-1);
    },
    onChangeTab(key) {
      this.activeTab = key;
    }
  }
};
